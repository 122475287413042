@keyframes blink {
    50% {
        opacity: 0;
    }
}

.geography {
    padding: 0 0 70px;
    box-sizing: border-box;
    overflow: hidden;

    .geography-header {
        margin: 0 auto 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .geography-title {
            max-width: 205px;
            h2 {
                margin: 0;
            }
        }
        .geography-desc {
            margin: 0;
            max-width: 340px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 160%;
            color: #181f29;
        }
    }

    .geography-map {
        max-width: 100%;
        width: 100%;
        position: relative;
        overflow-x: scroll;
        &::-webkit-scrollbar {
            width: 0;
        }
        scrollbar-width: none;
        display: flex;
        justify-content: center;
        .geography-map-image {
            padding: 0 20px;
            position: relative;
        }

        .geography-info-list {
            margin: 0;
            padding: 15px 20px 15px 30px;
            box-sizing: border-box;
            width: 250px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 120%;
            color: #181f29;
            background: #fff;
            box-shadow: 20px 5px 50px 2px rgba(179, 181, 198, 0.1);
            border-radius: 5px;
            opacity: 0;
            transition-duration: 0.4s;

            &.shown {
                opacity: 1;
                transition-duration: 0.4s;
            }

            &.srt {
                &.shown {
                    opacity: 1;
                    transition-duration: 0.4s;
                }
            }

            .geography-info {
                ul {
                    margin:0;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    list-style: none;
                    font-family: "Open Sans";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 120%;

                    color: #181f29;
                }

                &.items {
                    list-style: none;
                }
            }
        }

        .geography-map-dot {
            position: absolute;
            top: 415px;
            right: 725px;
            left: 510px;

            background: #fff;
            width: 6px;
            height: 6px;
            border: 6px solid #119c6f;
            border-radius: 100px;
            cursor: pointer;
            z-index: 2;

            .geography-map-dot-underlay {
                position: absolute;
                top: -12px;
                left: -12px;
                right: 0px;
                width: 30px;
                height: 30px;
                background: #119c6f;
                opacity: 0.2;
                border-radius: 100%;
                animation: blink 1.5s step-start 0s infinite;
                animation-timing-function: linear;
                z-index: 1;
            }

            &.srt {
                top: 276px;
                right: 1725px;
                left: 201px;
                border: 6px solid #2051a3;

                .geography-map-dot-underlay {
                    background: #2051a3;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .geography-map {
            display: block;
        }
    }

    @media (max-width: 768px) {
        padding: 0 0 50px;

        .geography-header {
            flex-direction: column;
            gap: 35px;
            align-items: flex-start;
            margin: 0 0 20px;
        }
    }
}
