.mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    display: none;

    .mobile-menu-header {
        position: relative;
        padding: 30px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(#181f29, 0.05);

        .mobile-menu-caption {
            margin: 0;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130%;
            color: #181f29;
        }

        .mobile-menu-close {
            position: absolute;
            top: 15px;
            right: 20px;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid rgba(32, 81, 163, 0.1);
            border-radius: 25px;
            outline: none;
            background: transparent;
        }
    }

    .mobile-menu-items {
        margin: 0;
        padding: 30px;
        display: flex;
        flex-direction: column;
        gap: 35px;
        list-style: none;

        .mobile-menu-item {
            display: flex;
            align-items: center;
            gap: 20px;

            svg {
                path {
                    fill: #181f29;
                }
            }
        }

        .mobile-menu-link {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 130%;
            color: #181f29;

            text-decoration: none;
        }
    }

    .mobile-menu-footer {
        padding: 20px 0 20px 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-top: 1px solid rgba(#181f29, 0.05);

        .header-phone {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #181f29;
            text-decoration: none;
        }

        .header-socials {
            display: flex;
            gap: 15px;
            margin: 0;
            padding: 0;
            list-style: none;
            .header-social {
                a {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 17px;
                    color: #181f29;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }
}
