.app-footer {
    padding: 40px 0;
    box-sizing: border-box;
    background: #f8f9fc;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .app-footer-content {
        margin: 30px 0 75px;
        display: flex;
        justify-content: space-between;
        gap: 20px;

        .app-footer-info {
            display: flex;
            flex-direction: column;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #181f29;

            a {
                max-width: fit-content;
                text-decoration: none;
                color: #181f29;
            }
            .app-footer-info-title {
                margin: 0 0 15px;
                font-size: 18px;
            }

            .app-footer-info-tel {
                margin: 0 0 10px;
                font-size: 14px;
            }
            .app-footer-info-mail {
                margin: 0 0 30px;
            }
            .app-footer-info-address {
                max-width: 287px;
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
            }

            .app-link {
                margin: 0 0 20px;

                &:last-of-type {
                    margin: 0;
                }
                a {
                    max-width: 100%;
                    color: #2051a3;
                }
            }
        }
    }

    .app-footer-policies {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #9b9fb3;

        a {
            text-decoration: none;
            color: #9b9fb3;
        }
    }

    @media (max-width: 900px) {
        .app-footer-content {
            margin: 30px 0;
            flex-direction: column;
            gap: 30px;
        }

        .app-footer-policies {
            flex-direction: column;
            gap: 30px;
        }
    }

    @media (max-width: 768px) {
        padding: 30px 0 35px;
    }
}
