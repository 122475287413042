@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&subset=cyrillic");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap");

.App {
    position: relative;
    margin: 0 auto;
    // display: flex;
    // flex-direction: column;
    overflow: hidden;

    .container {
        max-width: 1560px;
        width: 100%;
        margin: 0 auto;
        padding-right: 60px;
        padding-left: 60px;
        box-sizing: border-box;
    }

    @media (max-width: 1200px) {
        .container {
            padding-right: 40px;
            padding-left: 40px;
            box-sizing: border-box;
        }
    }

    @media (max-width: 1000px) {
        .container {
            padding-right: 20px;
            padding-left: 20px;
            box-sizing: border-box;
        }
    }
}
