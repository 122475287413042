.partners {
    padding: 0 0 70px;
    box-sizing: border-box;

    .partners-content {
        max-width: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        box-sizing: border-box;

        .partners-list {
            width: 100%;
            margin: 0 -3px -1px 0;
            padding: 0;
            box-sizing: border-box;
            list-style: none;
            display: flex;

            flex-wrap: wrap;

            .partners-item {
                background-color: #fff;
                border-bottom: 1px solid rgba(#9b9fb3, 0.2);
                border-right: 1px solid rgba(#9b9fb3, 0.2);
                box-sizing: border-box;
                flex: 1 0 276px;
                height: 170px;
                display: flex;
                justify-content: center;
                align-items: flex-start;

                &:empty {
                    height: 0;

                    border: none;
                }

                a {
                    height: 0;
                    text-decoration: none;
                    background: #fff;
                }
                svg {
                    path {
                        fill: #9b9fb3;
                        transition-duration: 0.6s;
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: #2051a3;
                            transition-duration: 0.6s;
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        padding: 0 0 50px;

        .partners-content {
            overflow: scroll;
            &::-webkit-scrollbar {
                width: 0;
            }
            scrollbar-width: none;
            justify-content: flex-start;

            .partners-list {
                flex-wrap: nowrap;

                .partners-item {
                    border-bottom: none;
                    &:nth-of-type(8) {
                        border-right: none;
                    }
                }
            }
        }
    }
}
