.goals {
    padding: 0 0 70px;

    .goal-title {
        width: 310px;
    }

    .goals-content {
        display: flex;
        justify-content: space-between;
        gap: 130px;

        .goals-info-list {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 30px;
            list-style: none;

            .goals-info-item {
                display: flex;
                gap: 30px;
                padding: 0 0 30px;
                box-sizing: border-box;
                border-bottom: 1px solid rgba(#181f29, 0.1);

                &:last-of-type {
                    border: none;
                }
            }

            .goals-info-caption {
                position: relative;
                min-width: 240px;
                height: 45px;
                padding: 0 20px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                gap: 20px;
                border: 1px solid rgba(32, 81, 163, 0.15);
                border-radius: 39px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                line-height: 130%;
                color: #181f29;

                .goals-info-number {
                    font-size: 13px;
                }

                .goals-info-title {
                    font-size: 18px;
                }
            }

            .goals-info-text {
                margin: 0;
                max-width: 510px;
                font-family: "Open Sans";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 160%;
                color: #181f29;
            }

            .goals-info-icon {
                position: absolute;
                top: auto;
                right: 20px;
                display: none;

                svg {
                    transform: rotate(45deg);
                    transition-duration: 0.2s;

                    path {
                        fill: #181f29;
                    }
                }

                &.clicked {
                    svg {
                        transform: rotate(90deg);
                        transition-duration: 0.2s;
                    }
                }
            }
        }

        .goals-picture {
            display: block;
            min-width: 480px;
            height: 428px;
            border-radius: 15px;
            background: no-repeat url("../../images/goals-bg.jpeg");
            background-size: 100% 100%;
            background-position: center;
            overflow: hidden;
        }
    }

    @media (max-width: 1200px) {
        .goals-content {
            gap: 80px;

            .goals-picture {
                display: none;
            }
        }
    }
    @media (max-width: 1560px) {
        .goals-content {
            gap: 80px;

            .goals-picture {
                min-width: 400px;
                height: 375px;
            }
        }
    }

    @media (max-width: 768px) {
        padding: 0 0 50px;

        .goals-content {
            gap: 20px;
            flex-direction: column;

            .goals-info-list {
                gap: 25px;
                .goals-info-item {
                    flex-direction: column;
                    gap: 20px;
                    border-bottom: none;
                    padding: 0;
                }

                .goals-info-caption {
                    max-width: 100%;
                    min-width: 335px;
                    cursor: pointer;
                }

                .goals-info-text {
                    display: none;
                    max-width: 100%;

                    &.clicked {
                        display: flex;
                    }
                }

                .goals-info-icon {
                    display: flex;
                }
            }
            .goals-picture {
                display: flex;
                min-width: auto;
                max-width: 100%;
                background-position: left top;
                background-size: contain;
            }
        }
    }
    @media (max-width: 520px) {
        .goals-content {
            .goals-picture {
                height: 300px;
            }
        }
    }
}
