.app-header {
    position: relative;
    // top: 20px;
    max-width: 100%;
    width: 100%;
    max-height: 66px ;
    padding: 20px 0 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: transparent;
    z-index: 10;
    box-sizing: border-box;
    

    .header-nav {
        display: flex;
        align-items: center;
        gap: 60px;
    }
   
    .header-menu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 30px;
        margin: 0;
        padding: 0;
        list-style: none;

        .header-menu-item {
            padding: 0 0 5px;
            transition-duration: 0.2s;

            a {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #fff;
                text-decoration: none;
            }

            &:hover {
                transition-duration: 0.2s;
                border-bottom: 1px solid #fff;
            }
        }
    }

    .header-contacts {
        display: flex;
        flex-direction: column;
        align-items: center;

        .header-phone {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #fff;
            text-decoration: none;
        }

        .header-socials {
            display: flex;
            gap: 15px;
            margin: 0;
            padding: 0;
            list-style: none;
            .header-social {
                a {
                    font-family: "Montserrat";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 17px;
                    color: #fff;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        .header-nav {
            gap: 40px;
        }

        .header-menu {
            gap: 15px;
        }
    }

    @media (max-width: 768px) {
        .header-menu {
            display: none;
        }

        .header-contacts {
            display: none;
        }
    }
}
