.foodtech {
    max-width: 1560px;
    width: 100%;
    padding: 0 0 70px;
    margin: 0 auto;

    .foodtech-list {
        display: flex;
        flex-direction: column;
        gap: 35px;
        margin: 0;
        padding: 0;
        list-style: none;

        .foodtech-item {
            position: relative;
            background: #edf4fd;
            border-radius: 10px;
            display: flex;

            &:last-of-type {
                .info-item-wrap .info-item-bg {
                    // width: 670px; with button
                    // height: 594px; with button
                    width: 636px;
                    height: 535px;
                    background: no-repeat url("../../images/ipad-pic.png");
                    background-position: right bottom;
                    background-size: cover;
                }
            }
        }
    }

    .info-item .info-item-list {
        column-gap: 45px;
        row-gap: 20px;
    }

    .info-item .info-item-header .info-item-tag {
        background: #2051a3;
    }

    @media (max-width: 1200px) {
        padding: 0 0 70px;
    }

    @media (max-width: 1000px) {
        padding: 0 0 70px;
    }

    @media (max-width: 768px) {
        padding: 0 0 50px;
        border-radius: 20px;

        .foodtech-title {
            padding: 0 20px;
        }

        .foodtech-list {
            .foodtech-item {
                border-radius: 20px;
            }
        }
    }

    @media (max-width: 500px) {
        .foodtech-list {
            .foodtech-item {
                .info-item-wrap .info-item .info-item-list {
                    column-gap: 25px;
                    row-gap: 15px;

                    .info-item-card {
                        &:first-of-type {
                            max-width: 240px;
                            grid-column: 1 / 3;
                        }
                        &:nth-of-type(2) {
                            display: none;
                        }
                    }
                }
                &:last-of-type {
                    .info-item-wrap {
                        overflow: hidden;
                        .info-item {
                            .info-item-list {
                                // margin: 0 0 370px; with button
                                margin: 0 0 340px;
                                grid-template-columns: 1fr;

                                .info-item-card {
                                    &:first-of-type {
                                        max-width: 225px;
                                    }
                                    &:nth-of-type(2) {
                                        display: none;
                                    }
                                    &:nth-of-type(3) {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .info-item-bg {
                            width: 375px;
                            height: 321px;
                            bottom: 20px;
                            background: no-repeat url("../../images/ipad-pic-mob.png");
                            background-position: right bottom;
                            background-size: cover;
                        }
                    }
                }
            }
        }
    }
}
