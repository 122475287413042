.app-link {
    display: flex;
    align-items: center;
    gap: 20px;
    transition-duration: 0.6s;
    padding: 0;

       
    &:hover {
        // padding: 0 0 0 10px;
        // transition-duration: 0.6s;
        // gap: 10px;

        svg {
            transition-duration: 0.6s;
            // padding: 0 0 5px 10px;
            transform: translate(10px, -5px);
        }
    }

    svg {
        transition-duration: 0.6s;
        path {
            fill: #2051a3;
        }
    }

    a {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 130%;
        color: #2051a3;
        text-decoration: none;
    }
}
