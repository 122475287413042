.about {
    position: relative;
    padding: 70px 0;
    box-sizing: border-box;
    height: auto;

    .about-content {
        position: relative;
        z-index: 2;
    }

    .about-text-container {
        max-width: 580px;
        margin: 0 0 30px;
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    .about-text {
        margin: 0;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 160%;
        color: #181f29;
    }

    .about-links {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .about-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 600px;
        background: no-repeat url(../../images/about-bg.png);
        background-position: 100% 100%;
        background-size: 60%;
    }

    @media (max-width: 1200px) {
        height: auto;
    }

    @media (max-width: 768px) {
        padding: 50px 0;

        .about-text-container {
            margin: 0 0 25px;
        }
    }
}
