.round-button {
    position: relative;
    width: 90px;
    height: 90px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    outline: none;
    border: none;
    background: #2051a3;
    transition-duration: 0.6s;
    cursor: pointer;
    text-decoration: none;

    .round-button-icon {
        svg {
            transition-duration: 0.6s;
        }
    }

    &:hover {
        transform: scale(0.8);
        transition-duration: 0.6s;

        .round-button-icon {
            svg {
                transition-duration: 0.6s;
                transform: translate(10px, -10px) scale(1.2);
            }
        }
    }

    &.transparent {
        width: 60px;
        height: 60px;

        transform: unset;
        background: transparent;
        border: 1px solid #181f29;

        .round-button-icon {
            svg {
                transition-duration: 0.6s;
                path {
                    fill: #181f29;
                }
            }
        }

        &:hover {
            background: #181f29;
            .round-button-icon {
                svg {
                    transform: translate(5px, -5px) scale(1);
                    path {
                        fill: #fff;
                    }
                }
            }
        }
    }
}
