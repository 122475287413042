.centres {
    max-width: 1560px;
    width: 100%;
    padding: 0 0 70px;
    margin: 0 auto;

    .centres-list {
        display: flex;
        flex-direction: column;
        gap: 35px;
        margin: 0;
        padding: 0;
        list-style: none;

        .centres-item {
            background: #f8f9fc;
            border-radius: 10px;
            overflow: hidden;
            &.nsk {
                .info-item-bg {
                    width: 620px;
                    height: 513px;
                    background: no-repeat url("../../images/nsk-centre-pic.png");
                    background-position: right bottom;
                    background-size: cover;
                }
            }

            &.srt {
                .info-item-bg {
                    width: 580px;
                    height: 516px;
                    background: no-repeat url("../../images/srt-centre-pic.png");
                    background-position: right bottom;
                    background-size: cover;
                }
            }

            &.rst {
                .info-item-bg {
                    width: 580px;
                    height: 516px;
                    background: no-repeat url("../../images/rst-centre-pic.png");
                    background-position: right bottom;
                    background-size: cover;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        padding: 0 0 70px;
    }

    @media (max-width: 1000px) {
        padding: 0 0 70px;
    }

    @media (max-width: 768px) {
        padding: 0 0 50px;

        .centres-list {
            .centres-item {
                border-radius: 20px;
            }
        }

        .centres-title {
            padding: 0 20px;
        }
    }

    @media (max-width: 500px) {
        padding: 0 0 50px;

        .centres-list {
            .centres-item {
                &.nsk {
                    .info-item-list {
                        margin: 0 0 340px;
                    }

                    .info-item-bg {
                        width: 100%;
                        height: auto;
                        background: no-repeat url("../../images/nsk-centre-pic-mob.png");
                        background-position: right center;
                        background-size: cover;
                        padding: 0 0 65% 0;
                    }
                }

                &.srt {
                    .info-item-list {
                        margin: 0 0 370px;
                    }
                    .info-item-bg {
                        top: auto;
                        bottom: 0;
                        width: 100%;
                        height: auto;

                        background: no-repeat url("../../images/srt-centre-pic-mob.png");
                        background-position: right center;
                        background-size: cover;
                        padding: 0 0 75% 0;
                    }
                }

                &.rst {
                    .info-item-list {
                        margin: 0 0 330px;
                    }
                    .info-item-bg {
                        top: auto;
                        bottom: 0;
                        width: 100%;
                        height: auto;
                        background: no-repeat url("../../images/rst-centre-pic.png");
                        background-size: cover;
                        width: 400px;
                        height: 340px;
                        background-position: 50% 0;
                    }
                }
            }
        }
    }

    @media (max-width: 440px) {
        .centres-list {
            .centres-item {
                .info-item-list {
                    margin: 0 0 300px;
                }

                &.nsk {
                    .info-item-bg {
                        padding: 0 0 65% 0;
                    }
                }

                &.srt {
                    .info-item-list {
                        margin: 0 0 360px;
                    }
                    .info-item-bg {
                        padding: 0 0 90% 0;
                    }
                }

                &.rst {
                    .info-item-bg {
                        background-position: -150% 0;
                    }
                }
            }
        }
    }
    @media (max-width: 375px) {
        .centres-list {
            .centres-item {
                &.srt {
                    .info-item-list {
                        margin: 0 0 310px;
                    }
                }
            }
        }
    }
}
