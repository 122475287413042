.banner {
    position: relative;
    padding: 0;
    box-sizing: border-box;
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: -70px 0 0;

    .about-bg {
        position: absolute;
        bottom: -600px;
        left: 0;
        width: 100%;
        height: 600px;
        background: no-repeat url(../../images/about-bg.png);
        background-position: 100% 100%;
        background-size: 60%;
    }

    .banner-swiper {
        position: relative;
        z-index: 2;
    }
    .banner-button {
        position: absolute;
        left: auto;
        bottom: 70px;
        width: fit-content;
        width: -moz-fit-content;
        z-index: 3;
        display: flex;
        align-items: center;
        gap: 15px;

        .banner-button-link {
            width: 60px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #fff;
            text-decoration: none;
        }
    }

    .banner-slider {
        box-sizing: border-box;
        .banner-slide {
            width: 100%;

            object-fit: cover;
            .banner-slide-text {
                position: absolute;
                top: calc(50% - 113px);
                left: auto;
                right: auto;
                max-width: 700px;
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 49px;
                color: #fff;
                margin: 0;
            }
            .banner-slide-video-container {
                width: 100%;
                padding: 0 0 50.2% 0;
                box-sizing: border-box;
                background: rgba(#252528, 0.4);

                .banner-slide-video {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .banner-slide-video-bg {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(#252528, 0.4);
                }
            }
        }
    }

    .slider-controls {
        width: 100%;
        position: absolute;
        right: auto;
        bottom: 70px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 25px;
        z-index: 2;
        margin: 0 0 0 -60px;

        .slider-buttons {
            display: flex;
            gap: 10px;

            .slider-button-prev,
            .slider-button-next {
                width: 45px;
                height: 45px;
                border: 1px solid rgba(#fff, 0.2);
                border-radius: 100px;
                background: transparent;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition-duration: 0.6s;

                &:hover {
                    transition-duration: 0.6s;
                    background: #fff;
                    svg {
                        path {
                            fill: #181f29;
                        }
                    }
                }
            }
            .slider-button-next {
                svg {
                    transform: rotate(180deg);
                }
            }
        }

        .slider-pages {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 160%;
            color: #fff;
            display: flex;
            align-items: center;

            .slider-page {
                opacity: 0.2;

                &.active {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 160%;
                    opacity: 1;
                    margin: 0 4px 0 0;
                }
            }
        }
    }

    @media (max-width: 1440px) {
        .about-bg {
            background-position: 100% 111%;
            background-size: 65%;
        }
    }

    @media (max-width: 1200px) {
        .banner-slider {
            .banner-slide {
                .banner-slide-text {
                    top: calc(50% - 75px);
                    left: 40px;
                    font-size: 26px;
                    line-height: 130%;
                }
            }
        }

        .banner-button {
            left: 40px;
            bottom: 45px;
        }

        .slider-controls {
            right: 40px;
            bottom: 45px;
            margin: 0 -40 0 -40px;
        }

        .about-bg {
            bottom: -500px;
            background-position: 100% -85px;
            background-size: 70%;
            opacity: 0.5;
            height: 500px;
        }
    }

    @media (max-width: 1000px) {
        .banner-slider {
            .banner-slide {
                .banner-slide-text {
                    left: 20px;
                    font-size: 26px;
                }
            }
        }

        .banner-button {
            left: 20px;
            bottom: 45px;
        }

        .slider-controls {
            right: 20px;
            bottom: 45px;
        }

        .slider-controls {
            display: none;
        }

        .about-bg {
            background-position: 100% -46px;
            background-size: 70%;
        }
    }

    @media (max-width: 768px) {
        margin: -66px 0 0;
        .banner-slider {
            .banner-slide {
                .banner-slide-text {
                    font-size: 32px;
                    top: 32%;
                }
                .banner-slide-video-container {
                    height: 580px;
                }
            }
        }

        .banner-button {
            bottom: 40px;
        }

        .slider-controls {
            display: none;
        }

        .about-bg {
            background: none;
            height: auto;
        }
    }
}
