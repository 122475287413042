.ecosystem {
    max-width: 1560px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 70px 60px;
    box-sizing: border-box;
    
    .ecosystem-title {
        width: 240px;
        padding: 0 0 30px;
    }

    .ecosystem-caption {
        margin: 0 0 30px;
        width: 230px;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
        opacity: 1;
        transition-duration: 0.8s;
        color: #181f29;

        &.hovered {
            opacity: 0;
            transition-duration: 0.8s;
        }
    }
    .ecosystem-content {
        position: relative;
        width: 300%;
        transition-duration: 0.8s;
        cursor: pointer;
        background: none;

        &.hovered {
            transition-duration: 0.8s;
            transform: translateX(-260px);
        }

        .ecosystem-line {
            position: absolute;
            top: -59px;
            left: 355px;
            z-index: 1;

            &.first {
                top: -15px;
                left: 350px;
            }

            &.third {
                top: -108px;
                left: 399px;
            }

            svg {
                g {
                    opacity: 0;
                    transition-duration: 0.8s;
                }
            }

            &.hovered {
                svg {
                    g {
                        opacity: 0.8;
                        transition-duration: 0.8s;
                    }
                }
            }
        }
    }

    .ecosystem-infograph {
        position: relative;
        display: flex;
        gap: 60px;
        width: 100%;
        height: 260px;
        border-top: 2px solid #2051a3;
    }

    .ecosystem-infograph-undelay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: inherit;
        background: #fff;
        z-index: 2;
    }

    .ecosystem-info {
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 230px;
        min-width: 230px;
        width: 100%;
        padding: 30px 0 0;
        box-sizing: border-box;
        z-index: 3;

        &:empty {
            height: 0;
        }

        .ecosystem-dot {
            position: absolute;
            top: -10px;
            left: -3px;
            width: 17px;
            height: 17px;
            background-color: #fff;
            border: 2px solid #2051a3;
            border-radius: 100px;
            box-sizing: border-box;
        }

        .ecosystem-circle {
            position: absolute;
            top: -120px;
            left: -10px;
            width: 240px;
            height: 240px;
            padding: 35px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 12px;
            background: #2051a3;
            border-radius: 100%;

            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;

            color: #f8f8fa;
        }

        .ecosystem-info-title {
            max-width: 215px;
            margin: 0 0 15px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #000;
        }

        .ecosystem-text {
            margin: 0;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 160%;
            color: #181f29;
            opacity: 1;
            transition-duration: 0.8s;

            &.hovered {
                opacity: 0;
                transition-duration: 0.8s;
            }

            &.small {
                width: 150px;
            }
        }
    }

   

    @media (max-width: 1200px) {
        padding: 0 0 70px 40px;
    }

    @media (max-width: 1000px) {
        padding: 0 0 70px 20px;
    }

    @media (max-width: 768px) {
        padding: 0 0 50px 20px;
        position: relative;

        .ecosystem-title {
            position: absolute;
            top: 0;
            left: 20px;
        }

        .ecosystem-content {
            position: relative;
            top: 0;
            left: -10px;
            width: 100%;
            padding: 165px 0 0 10px;
            &.hovered {
                transform: none;
            }
            overflow-x: scroll;
            &::-webkit-scrollbar {
                width: 0;
            }
            scrollbar-width: none;

            .ecosystem-line {
                position: absolute;
                top: 160px;
                left: 192px;
                z-index: 1;

                &.first {
                    top: 204px;
                    left: 198px;
                }

                &.third {
                    top: 73px;
                    left: 166px;
                }
            }
        }

        .ecosystem-caption {
            font-size: 16px;
            &.hovered {
                opacity: 1;
            }
        }

        .ecosystem-infograph {
            width: 1200px;
            height: 200px;
            gap: 30px;
        }

        .ecosystem-info {
            padding: 20px 0 0;

            &:first-of-type {
                max-width: 170px;
                min-width: auto;
            }

            &:nth-of-type(2) {
                max-width: 140px;
                min-width: auto;
            }
            .ecosystem-text {
                font-size: 14px;
                &.hovered {
                    opacity: 1;
                }
            }

            .ecosystem-circle {
                top: -85px;
                left: -33px;
                width: 170px;
                height: 170px;
                padding: 22px;
                font-size: 12px;
            }
        }
    }
}
